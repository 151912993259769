/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { SimpleQuiz } from 'src/app/data/models/SimpleQuiz';
import { CompTypeHelper } from 'src/app/helpers/CompTypeHelper';

@Component({
  selector: 'app-modal-sub-exp-configurator',
  templateUrl: './modal-sub-exp-configurator.component.html',
  styleUrls: ['./modal-sub-exp-configurator.component.scss']
})
export class ModalSubExpConfiguratorComponent implements OnInit, AfterViewInit {
  // @ViewChild('rootElem', {read: ViewContainerRef, static: true}) rootElem: ViewContainerRef;
  @ViewChildren('rootElem', { read: ViewContainerRef }) rootElems: QueryList<ViewContainerRef>;

  rootElemsList: ViewContainerRef[] = [];

  configuratorInstance: any = null;

  config: any[] = [];
  baseConfig: any = null;
  isAdd: boolean = false;
  isTranslate: boolean = false;
  data: any = null;
  genericDefault: any = { SimpleQuiz: new SimpleQuiz() };
  selectedLanguage: string;
  idx: number = 0;

  isValidEventEmitter: EventEmitter<{ isValid: boolean; idx: number }> = new EventEmitter<{ isValid: boolean; idx: number }>();
  isValid: { idx: number; valid: boolean }[] = [];
  isSubmitted: boolean = false;
  isSubmittedSubject: Subject<void> = new Subject<void>();

  slideWidth: number = 735;


  constructor(
    private navParams: NavParams,
    private modalController: ModalController
  ) { }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isAdd && event.key === 'ArrowLeft') {
      console.log('Flèche gauche pressée');
      this.onPrevClick();
    }

    if (!this.isAdd && event.key === 'ArrowRight') {
      console.log('Flèche droite pressée');
      this.onNextClick();
    }
  }

  ngOnInit(): void {
    if (this.navParams.data && this.navParams.data.isTranslate) this.isTranslate = this.navParams.data.isTranslate;
    if (this.navParams.data && this.navParams.data.idx) this.idx = this.navParams.data.idx;

    if (this.navParams.data && this.navParams.data.config) {
      this.config = this.navParams.data.config;

      if (this.isTranslate && Array.isArray(this.config) && this.config?.length > this.idx) {
        this.config = [JSON.parse(JSON.stringify((this.config[this.idx])))];
      }
    }
    if (this.navParams.data && this.navParams.data.baseConfig) this.baseConfig = this.navParams.data.baseConfig;
    if (this.navParams.data && this.navParams.data.isAdd) this.isAdd = this.navParams.data.isAdd;
    if (this.navParams.data && this.navParams.data.genericDefault) this.genericDefault = this.navParams.data.genericDefault;

    this.isValidEventEmitter.subscribe((validEvent: { isValid: boolean; idx: number }) => {
      if (!this.isValid) this.isValid = [];

      const idx = this.isValid.findIndex(valid => valid.idx === validEvent.idx);
      if (idx >= 0) {
        this.isValid[idx].valid = validEvent.isValid;
      } else {
        this.isValid.push({ idx: validEvent.idx, valid: validEvent.isValid });
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.rootElems) {
        this.rootElemsList = this.rootElems.toArray();
        this.setupFullSlides();
      }
    }, 0);
  }

  setupFullSlides() {
    this.rootElemsList.forEach((rootElem, idx) => {
      this.setupSlide(rootElem, this.config[idx]);
    });
  }

  setupSlide(rootElem: ViewContainerRef, config: any) {
    if (!config || !rootElem) {
      console.log('no config or rootElem');
      console.log('rootElem', rootElem);
      console.log('config', config);
      this.closeModal(null, 'close');
      return;
    }

    const generic = CompTypeHelper.getGenericElementConfiguration(this.genericDefault);
    if (!generic) {
      console.log('no generic');
      this.closeModal(null, 'close');
      return;
    }

    this.configuratorInstance = rootElem.createComponent(generic.configuratorType);

    if (!this.configuratorInstance?.instance) {
      console.log('no configurator instance');
      this.closeModal(null, 'close');
      return;
    }

    const configGeneric = { SimpleQuiz: config };

    this.configuratorInstance.instance.config = configGeneric;
    this.configuratorInstance.instance.baseConfig = this.baseConfig;
    this.configuratorInstance.instance.selectedLanguage = this.selectedLanguage;
    this.configuratorInstance.instance.isValidEventEmitter = this.isValidEventEmitter;
    this.configuratorInstance.instance.isSubmittedSubject = this.isSubmittedSubject;
    this.configuratorInstance.instance.idx = config?.index;
  }

  async closeModal(data: any = null, status: string) {
    if (data === null && this.config?.length > 1 && this.isAdd === true) {
      // delete last elem of config and add the rest to data
      data = { config: this.config.slice(0, this.config.length - 1) };
      status = 'confirm';
    }

    await this.modalController.dismiss(data, status);
  }

  save(addNewQuestion: boolean = false) {
    this.isSubmitted = true;
    this.isSubmittedSubject.next();
    if (this.isValid.some(item => item.valid === false)) {
      const idxInvalid = this.isValid.findIndex(item => item.valid === false);
      this.idx = idxInvalid;
      return;
    }

    if (addNewQuestion) {
      this.isSubmitted = false;
      this.resetModalAndAddNewQuestion();
    } else {
      this.closeModal({ config: this.config, addNewQuestion }, 'confirm');
    }
  }

  resetModalAndAddNewQuestion() {
    const newItem = JSON.parse(JSON.stringify(this.genericDefault.SimpleQuiz));
    newItem.index = this.config[this.config.length - 1].index + 1;
    this.config.push(newItem);

    setTimeout(() => {
      this.idx = this.config.length - 1;
      this.rootElemsList = this.rootElems.toArray();
      this.setupSlide(this.rootElemsList[this.idx], this.config[this.idx]);
    }, 100);
  }

  onPrevClick() {
    if (this.idx <= 0) return;

    this.idx--;
  }

  onNextClick() {
    if (this.config?.length === this.idx + 1) return;

    this.idx++;
  }

}
