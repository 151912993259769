<ion-content>
  <div class="modal-container">
    <div class="contact-element">
      <h4>Un problème ? Une question ? N'hésitez pas à nous contacter pour plus d'informations.</h4>
    </div>
    <div class="contact-element">
      <h3>Contacter par téléphone</h3>
      <div>Nos équipes sont disponibles pour vous répondre du lundi au vendredi de 9h30 à 18h</div>
      <h3><b>06 42 07 72 50</b></h3>
    </div>
    <div class="contact-element">
      <h3>Contacter via email</h3>
      <div>Nos équipes sont disponibles pour vous répondre du lundi au vendredi de 9h30 à 18h</div>
      <h3><b>support&#64;mocli.fr</b></h3>
    </div>
  </div>
</ion-content>