import { UserToken } from './../data/models/UserToken';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './AuthService';
import { HttpResponse } from '@capacitor/core';

export class HttpService {
  root: string;

  constructor(
    private httpClient: HttpClient,
    endpoint: string,
    private authService: AuthService,
  ) {
    if (endpoint.endsWith('/')) {
      this.root = endpoint.split('/')[0];
    } else {
      this.root = endpoint;
    }
  }

  validApi(api: string): string {
    if (api.startsWith('/')) {
      return api;
    } else {
      return '/' + api;
    }
  }

  getApi<T>(api: string, params: HttpParams = null): Observable<T> {
    let headers = new HttpHeaders()
      .append('target-service', 'services')
      .append('content-type', 'application/json');

    if(this.authService?.userLogged?.value?.token) {
      headers = headers.append('Authorization', 'bearer ' + this.authService.userLogged.value.token);
    }

    const url = this.root + this.validApi(api);
    return this.httpClient.get<T>(url, { headers, params });
  }

  postApi<T>(api: string, data: any = null, params: HttpParams = null, contentType: string = 'application/json'): Observable<T> {
      let headers = new HttpHeaders()
        .append('target-service', 'services')
        .append('content-type', contentType);

      if(this.authService?.userLogged?.value?.token) {
        headers = headers.append('Authorization', 'bearer ' + this.authService.userLogged.value.token);
      }

      const url = this.root + this.validApi(api);
      return this.httpClient.post<T>(url, data, { headers, params, responseType: 'json' });
  }

  putApi(api: string, data: any = null, params: HttpParams = null,
             contentType: string = 'application/json'): Observable<Blob> {
      let headers = new HttpHeaders()
        .append('target-service', 'services')
        .append('content-type', contentType);

      if(this.authService?.userLogged?.value?.token) {
        headers = headers.append('Authorization', 'bearer ' + this.authService.userLogged.value.token);
      }

      const url = this.root + this.validApi(api);
      return this.httpClient.put(url, data, { headers, params, responseType: 'blob' });
  }
}
