<!-- <div class="modal-confirm">
  <h1>
    {{modalTitle}}
  </h1>
  <button (click)="closeModal('cancel')">cancel</button>
  <button (click)="closeModal('confirm')">confirm</button>
</div> -->


<ion-content>
  <ion-grid >
    <div class="icon-close" (click)="closeModal('cancel')" style="cursor: pointer;">
      <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
    </div>
    <ion-row *ngIf="modalTitle" class="title">
      <h1>
        {{modalTitle}}
      </h1>
    </ion-row>
    <ion-row *ngIf="modalDescription">
      <h3>{{modalDescription}}</h3>
    </ion-row>
    <ion-row *ngIf="qrCodeValue" style="margin: 20px 0;" (click)="openExperience()">
      <qrcode [width]="170" *ngIf="qrCodeValue.length > 0" [qrdata]="qrCodeValue"></qrcode>
    </ion-row>
    <ion-row>
      <div class="pin-container" *ngIf="pin">
        Code secret : <b>{{pin}}</b>
        <div class="info" pTooltip="Code secret pour accéder à l’expérience avant sa date de démarrage" tooltipPosition="right">i</div>
      </div>
    </ion-row>
    <!-- <ion-row class="controls">
      <div class="mocli-button button cancel" (click)="closeModal('cancel')">Annuler</div>
      <div class="mocli-button button confirm" style="margin-left: 10px;" (click)="closeModal('confirm')">Confirmer</div>
    </ion-row> -->
  </ion-grid>
</ion-content>