import { MocliExperience } from './MocliExperience';

export class IntroAuth extends MocliExperience {
    constructor() {
        super();
    }
}

export class LoginAuth extends MocliExperience {
    constructor() {
        super();
    }
}

export class MissingPasswordAuth extends MocliExperience {
    constructor() {
        super();
    }
}

export class RegisterAuth extends MocliExperience {
    constructor() {
        super();
    }
}

export class CodeHuntLeaderboard extends MocliExperience {
    constructor() {
        super();
    }
}

export class CodeHuntScanner extends MocliExperience {
    constructor() {
        super();
    }
}

export class CodeHuntPrizes extends MocliExperience {
    constructor() {
        super();
    }
}

export class CodeHuntDashboard extends MocliExperience {
    constructor() {
        super();
    }
}

export class CodeHuntQuizList extends MocliExperience {
    constructor() {
        super();
    }
}

export class AccountPage extends MocliExperience {
    constructor() {
        super();
    }
}

