<div class="codehunt-content-main-container mocli-box-content" #codehuntContentMainContainer
    id="codehuntContentMainContainer">

    <div class="configurator-layer">
        <!-- Configurateur -->
        <div class="configurator-container" [ngClass]="{'configurator-container-overview': !editorMode}">

            <div class="configurator-title-container">
                <div class="configurator-title">{{selectedCode?.code}}</div>
                <div class="status-badge-container" [pTooltip]="!isBaseLanguage ? 'À modifier depuis la langue principale' : null">
                    <app-status-badge [disabled]="!isBaseLanguage" (onStatusChanged)="onStatusChanged($event, selectedCode)"
                        [status]="selectedCode?.status"></app-status-badge>
                </div>

                <div *ngIf="isBaseLanguage" class="delete-code-container" (click)="onDeleteCodeClick()">Supprimer ce code</div>
            </div>

            <!-- Indice -->
            <div class="configurator-block" *ngIf="contentEditorFormGroup">
                <app-input-textarea id="input-hint-container" [inputFormGroup]="contentEditorFormGroup"
                    [inputFormControlName]="'hint'" [maxLength]="300"
                    [placeholder]="!isBaseLanguage && getBaseCode(selectedCode)?.hint?.content ? 'À traduire : ' + getBaseCode(selectedCode)?.hint?.content : 'Écrire ici...'" 
                    [isFormSubmitted]="hasErrorCode(selectedCode) && !isUpdating" customId="input-hint" label="Indice"
                    labelTooltip="Apparaît dans le jeu dans un ordre aléatoire pour guider le joueur vers le code. Une fois le code scanné, l’indice ne sera plus visible."
                    [resetListener]="resetInputSubject"></app-input-textarea>
            </div>

            <!-- Catégories -->
            <div class="configurator-block" *ngIf="hasMultiCategories && contentEditorFormGroup && (isBaseLanguage || (!isBaseLanguage && contentEditorFormGroup?.get('category')?.value))">
                <app-input-label label="Catégorie"
                    labelTooltip="Permet de classer les indices par thématiques (ex : Hall A / Hall B ou Conférences / Stands)"></app-input-label>
                <div class="question-content category-container" style="margin-top: 5px;"
                    [formGroup]="contentEditorFormGroup" id="mInput_select-220px">

                    <!-- [disabled]="!isBaseLanguage || !currentMultiCategories || currentMultiCategories?.length <= 0" -->
                    <p-dropdown placeholder="Choisir une catégorie..." [formControlName]="'category'"
                        [pTooltip]="!isBaseLanguage ? 'À modifier depuis la langue principale' : null"
                        [options]="currentMultiCategories"
                        (onChange)="onCategoryChange()" [showClear]="true"></p-dropdown>
                    <div class="control-category-container" *ngIf="isBaseLanguage" (click)="isBaseLanguage ? addCategory() : ''"
                        pTooltip="Ajouter une catégorie" tooltipPosition="top">
                        <img src="https://cdn.mocli.fr/icon/add-blue.svg"
                            [ngClass]="{'control-active': isBaseLanguage}" alt="ajouter une catégorie">
                    </div>
                    <div class="control-category-container" *ngIf="isBaseLanguage"
                        [pTooltip]="contentEditorFormGroup?.get('category')?.value ? 'Éditer la catégorie choisie' : null"
                        tooltipPosition="top">
                        <img src="https://cdn.mocli.fr/icon/edit-blue.svg"
                            [ngClass]="{'control-active': contentEditorFormGroup?.get('category')?.value && isBaseLanguage}"
                            (click)="(contentEditorFormGroup?.get('category')?.value && isBaseLanguage) ? editCategory() : ''"
                            alt="edit">
                    </div>
                    <div class="control-category-container" *ngIf="isBaseLanguage"
                        [pTooltip]="contentEditorFormGroup?.get('category')?.value ? 'Supprimer la catégorie choisie' : null"
                        tooltipPosition="top">
                        <img src="https://cdn.mocli.fr/icon/delete-blue.svg"
                            [ngClass]="{'control-active': contentEditorFormGroup?.get('category')?.value && isBaseLanguage}"
                            (click)="(contentEditorFormGroup?.get('category')?.value && isBaseLanguage) ? removeDefinitelyCategory() : ''"
                            alt="delete">
                    </div>
                </div>
            </div>

            <!-- Emplacement -->
            <div class="configurator-block" *ngIf="contentEditorFormGroup && (isBaseLanguage || (!isBaseLanguage && getBaseCode(selectedCode)?.hint?.location))">
                <app-input-text [inputFormGroup]="contentEditorFormGroup" [inputFormControlName]="'location'"
                    [pTooltip]="!isBaseLanguage ? 'À modifier depuis la langue principale' : null"
                    [placeholder]="'Écrire ici...'" [maxLength]="100"
                    [isFormSubmitted]="hasErrorCode(selectedCode) && !isUpdating" customId="input-location"
                    label="Emplacement"
                    [disabled]="!isBaseLanguage"
                    [value]="!isBaseLanguage ? (selectedCode?.location ?? null) : null"
                    labelTooltip="Indicatif pour votre suivi, non visible par les joueurs. Apparaît également dans les statistiques pour le nombre de scans par QR codes"></app-input-text>
            </div>

            <!-- Question bonus -->
            <div class="configurator-block-quiz" *ngIf="isBaseLanguage || (!isBaseLanguage && getSubExpConfigForCode(selectedCode)?.length > 0)">
                <app-input-label label="Question bonus"
                    labelTooltip="À répondre après avoir scanné le code, jusqu’à 5 questions maximum par code"></app-input-label>

                <div class="configurator-quiz-container">
                    <div class="configurator-quiz-item" *ngFor="let quiz of getSubExpConfigForCode(selectedCode); let i = index">
                        <!-- Idx question -->
                        <div class="quiz-item_idx">{{ i + 1 }}.</div>
                    
                        <!-- Controls (langue de base) -->
                        <div *ngIf="isBaseLanguage" class="quiz-item_controls">
                            <div class="control-container" (click)="onAddEditSubExpQuestionClick(i)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_8325_16542)">
                                        <path
                                            d="M2 11.4997V13.9997H4.5L11.8733 6.62638L9.37333 4.12638L2 11.4997ZM13.8067 4.69305C14.0667 4.43305 14.0667 4.01305 13.8067 3.75305L12.2467 2.19305C11.9867 1.93305 11.5667 1.93305 11.3067 2.19305L10.0867 3.41305L12.5867 5.91305L13.8067 4.69305Z"
                                            fill="#002852" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_8325_16542">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div class="control-container" (click)="onMoveSubExpQuestionTopClick(i)"
                                [ngClass]="{'control-container-disabled': i === 0}">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.5 8L2.64563 9.14562L7.1875 4.61187L7.1875 14.5L8.8125 14.5L8.8125 4.61187L13.3463 9.15375L14.5 8L8 1.5L1.5 8Z"
                                        fill="#002852" />
                                </svg>
                            </div>
                            <div class="control-container" (click)="onMoveSubExpQuestionBottomClick(i)"
                                [ngClass]="{'control-container-disabled': i === selectedCode.subExpConfig?.length - 1}"
                                style="margin-left: 4px">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.5 8L13.3544 6.85438L8.8125 11.3881L8.8125 1.5L7.1875 1.5L7.1875 11.3881L2.65375 6.84625L1.5 8L8 14.5L14.5 8Z"
                                        fill="#002852" />
                                </svg>
                            </div>
                            <div class="control-container" (click)="onDeleteSubExpQuestionClick(i)"
                                style="margin-left: 4px">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.66675 1.33301L6.00008 1.99967H2.66675V3.33301H4.66675H11.3334H13.3334V1.99967H10.0001L9.33342 1.33301H6.66675ZM3.33341 4.66634V13.333C3.33341 14.0663 3.93341 14.6663 4.66675 14.6663H11.3334C12.0667 14.6663 12.6667 14.0663 12.6667 13.333V4.66634H3.33341Z"
                                        fill="#002852" />
                                </svg>
                            </div>
                        </div>

                        <!-- Bouton traduction -->
                        <div *ngIf="!isBaseLanguage" class="quiz-item-translate-container">
                            <div (click)="onTranslateSubExpClick(i)" class="quiz-item-translate-control tranlate-control-invalid" *ngIf="quiz?.toTranslate === true">À traduire</div>
                            <div (click)="onTranslateSubExpClick(i)" class="quiz-item-translate-control tranlate-control-valid" *ngIf="quiz?.toTranslate === false">Traduit</div>
                        </div>

                        <!-- Intitulé Question -->
                        <div class="quiz-item_question">{{quiz?.question ?? ''}}</div>

                        <!-- Réponses -->
                        <div class="quiz-item_answers-container">
                            <div *ngFor="let answer of quiz?.answers; let answerIdx = index" class="quiz-item_answer"
                                [ngClass]="{'quiz-item_answer-good': answer?.correct === true}">
                                {{answerIdx + 1}}. {{answer?.answer}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="add-element-bonus" *ngIf="isBaseLanguage && (selectedCode?.quizLength < 5)"
                    (click)="onAddEditSubExpQuestionClick()">
                    <div class="add-element-bonus-add">+</div>
                    <div class="add-element-bonus-content">Ajouter une question</div>
                </div>
            </div>
        </div>

        <div class="configurator-transition" [ngClass]="{'configurator-transition-active': isUpdating}"></div>
    </div>

    <div class="error-blocker error-blocker-left"></div>
    <div class="error-blocker error-blocker-right"></div>

    <div class="top-layer-container" [ngClass]="{'top-layer-container-afterEditorMode': afterEditorMode === true}">

        <!-- List d'erreurs -->
        <ng-container *ngFor="let code of codesList; let codeIdx = index">
            <div class="test-error" [style]="'top: ' + (50 + codeIdx * 33) + 'px'" [ngClass]="{
                    'test-error-active': hasErrorCode(code),
                    'test-error-editor': editorMode === true,
                }" (click)="hasErrorCode(code) ? onEditClick(code) : null">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.68564 2.14572C8.26675 1.17381 9.67402 1.17165 10.2581 2.14176L16.631 12.7263C17.2329 13.726 16.5129 15 15.346 15H2.64453C1.47962 15 0.759302 13.7301 1.3571 12.7302L7.68564 2.14572Z"
                        fill="#D86C6C" />
                    <path
                        d="M9.78125 5.89062L9.62012 10.8271H8.44824L8.28223 5.89062H9.78125ZM8.24316 12.3555C8.24316 12.1471 8.31478 11.9746 8.45801 11.8379C8.60449 11.6979 8.79655 11.6279 9.03418 11.6279C9.27507 11.6279 9.46712 11.6979 9.61035 11.8379C9.75358 11.9746 9.8252 12.1471 9.8252 12.3555C9.8252 12.5573 9.75358 12.7282 9.61035 12.8682C9.46712 13.0081 9.27507 13.0781 9.03418 13.0781C8.79655 13.0781 8.60449 13.0081 8.45801 12.8682C8.31478 12.7282 8.24316 12.5573 8.24316 12.3555Z"
                        fill="white" />
                </svg>
            </div>
        </ng-container>

        <div class="top-layer">

            <div class="left-container">
                <div class="codehunt-content-header"
                    (click)="editorMode = false; editorModeChanged.next({ state: false, initiator: 'parent' }); editorModeChanged.next({ state: false, initiator: 'child' })">
                    <div class="codehunt-content-header_title">QR Codes</div>
                </div>

                <div class="codehunt-content-table">
                    <div class="codehunt-content-row codehunt-content-row-code"
                        *ngFor="let code of codesList; let codeIdx = index" (click)="onEditClick(code)">
                        <div class="codehunt-content-item code"
                            [ngClass]="{'code-no-border': editorMode && (selectedCodeIdx === codeIdx || (selectedCodeIdx - 1) === codeIdx), 'code-odd': codeIdx % 2 === 0, 'code-even': codeIdx % 2 === 1, 'code-selected': editorMode && selectedCodeIdx === codeIdx}">
                            {{code?.code ?? ''}}</div>
                        <!-- <div class="codehunt-content-item-error" [ngClass]="{'codehunt-content-item-error-active': hasErrorCode(code), 'codehunt-content-item-error-selected': selectedCodeIdx === codeIdx, 'codehunt-content-item-error-overview': !editorMode}">
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.68564 2.14572C8.26675 1.17381 9.67402 1.17165 10.2581 2.14176L16.631 12.7263C17.2329 13.726 16.5129 15 15.346 15H2.64453C1.47962 15 0.759302 13.7301 1.3571 12.7302L7.68564 2.14572Z" fill="#D86C6C"/>
                                <path d="M9.78125 5.89062L9.62012 10.8271H8.44824L8.28223 5.89062H9.78125ZM8.24316 12.3555C8.24316 12.1471 8.31478 11.9746 8.45801 11.8379C8.60449 11.6979 8.79655 11.6279 9.03418 11.6279C9.27507 11.6279 9.46712 11.6979 9.61035 11.8379C9.75358 11.9746 9.8252 12.1471 9.8252 12.3555C9.8252 12.5573 9.75358 12.7282 9.61035 12.8682C9.46712 13.0081 9.27507 13.0781 9.03418 13.0781C8.79655 13.0781 8.60449 13.0081 8.45801 12.8682C8.31478 12.7282 8.24316 12.5573 8.24316 12.3555Z" fill="white"/>
                            </svg>                        
                        </div> -->
                    </div>
                    <div class="selected-row"
                        [style]="'top: ' + (selectedCodeIdx * 33 - 1 > 0 ? selectedCodeIdx * 33 - 1 : 0) + 'px'"
                        [ngClass]="{'selected-row-disabled': !editorMode || !selectedCode}"></div>

                    <div class="codehunt-content-row bg-white">
                        <div class="codehunt-content-item counter">{{currentCodesLength}} / {{maxCodes}}</div>
                    </div>
                    <div class="codehunt-content-row bg-white">
                        <div class="codehunt-content-item add-btn"
                            [pTooltip]="!isBaseLanguage ? 'À modifier depuis la langue principale' : null"
                            [ngClass]="{'add-btn-disabled': currentCodesLength === maxCodes || !isBaseLanguage}"
                            (click)="onAddElementClick()">
                            <div class="add-btn-text-image">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_8220_17313)">
                                        <circle cx="12" cy="10" r="8" fill="white" />
                                        <path
                                            d="M12 2C10.4178 2 8.87103 2.46919 7.55544 3.34824C6.23985 4.22729 5.21447 5.47672 4.60897 6.93853C4.00347 8.40034 3.84504 10.0089 4.15372 11.5607C4.4624 13.1126 5.22433 14.538 6.34315 15.6569C7.46197 16.7757 8.88743 17.5376 10.4393 17.8463C11.9911 18.155 13.5997 17.9965 15.0615 17.391C16.5233 16.7855 17.7727 15.7602 18.6518 14.4446C19.5308 13.129 20 11.5822 20 10C20 7.87827 19.1571 5.84344 17.6569 4.34315C16.1566 2.84285 14.1217 2 12 2ZM12 16.8571C10.6438 16.8571 9.31803 16.455 8.19038 15.7015C7.06273 14.948 6.18383 13.8771 5.66483 12.6241C5.14583 11.3711 5.01003 9.99239 5.27462 8.66224C5.5392 7.33208 6.19228 6.11026 7.15127 5.15127C8.11026 4.19228 9.33209 3.5392 10.6622 3.27461C11.9924 3.01003 13.3711 3.14582 14.6241 3.66483C15.8771 4.18383 16.948 5.06272 17.7015 6.19037C18.455 7.31803 18.8571 8.64378 18.8571 10C18.8571 11.8186 18.1347 13.5628 16.8487 14.8487C15.5628 16.1347 13.8186 16.8571 12 16.8571ZM16 10C16 10.1516 15.9398 10.2969 15.8326 10.4041C15.7255 10.5112 15.5801 10.5714 15.4286 10.5714H12.5714V13.4286C12.5714 13.5801 12.5112 13.7255 12.4041 13.8326C12.2969 13.9398 12.1516 14 12 14C11.8485 14 11.7031 13.9398 11.5959 13.8326C11.4888 13.7255 11.4286 13.5801 11.4286 13.4286V10.5714H8.57143C8.41988 10.5714 8.27453 10.5112 8.16737 10.4041C8.06021 10.2969 8 10.1516 8 10C8 9.84845 8.06021 9.7031 8.16737 9.59594C8.27453 9.48877 8.41988 9.42857 8.57143 9.42857H11.4286V6.57143C11.4286 6.41988 11.4888 6.27453 11.5959 6.16737C11.7031 6.0602 11.8485 6 12 6C12.1516 6 12.2969 6.0602 12.4041 6.16737C12.5112 6.27453 12.5714 6.41988 12.5714 6.57143V9.42857H15.4286C15.5801 9.42857 15.7255 9.48877 15.8326 9.59594C15.9398 9.7031 16 9.84845 16 10Z"
                                            fill="#002852" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_8220_17313" x="0" y="0" width="24" height="24"
                                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="2" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                result="effect1_dropShadow_8220_17313" />
                                            <feBlend mode="normal" in="SourceGraphic"
                                                in2="effect1_dropShadow_8220_17313" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            <div class="add-btn-text">Ajouter un QR Code</div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="right-container"
                [ngClass]="{'right-container-editor': editorMode === true, 'right-container-afterEditorMode': afterEditorMode === true}">
                <!-- Vue d'ensemble -->
                <div class="overview-container">

                    <!-- Header du tableau -->
                    <div class="codehunt-content-header">
                        <div class="codehunt-content-header-item codehunt-content-header_edit"></div>
                        <div class="codehunt-content-header-item codehunt-content-header_hint">Indice</div>
                        <div class="codehunt-content-header-item codehunt-content-header_location">Emplacement</div>
                        <div class="codehunt-content-header-item codehunt-content-header_quiz">Quiz</div>
                        <div class="codehunt-content-header-item codehunt-content-header_status">
                            Statut
                            <div class="tooltip-info"
                                pTooltip="Un code désactivé sera considéré comme invalide dans le jeu et ne permet pas de gagner des points"
                                tooltipPosition="left">i</div>
                        </div>
                    </div>

                    <!-- Langue de base (fr par défaut) -->
                    <div class="codehunt-content-table" *ngIf="isBaseLanguage">
                        <div class="codehunt-content-row" *ngFor="let code of codesList; let i = index">
                            <!-- Bouton edit -->
                            <div class="codehunt-content-item codehunt-content-item_edit">
                                <app-edit-button (editButtonClicked)="onEditClick(code)"></app-edit-button>
                            </div>

                            <!-- Indice -->
                            <div class="codehunt-content-item codehunt-content-item_hint"
                                [ngClass]="{'codehunt-content-item-hover': !editorMode, 'codehunt-content-item-error': code?.hint?.length > 300}"
                                (click)="onEditClick(code, 'hint')">{{code?.hint ?? ''}}</div>
        
                            <!-- Emplacement -->
                            <div class="codehunt-content-item codehunt-content-item_location"
                                [ngClass]="{'codehunt-content-item-hover': !editorMode, 'codehunt-content-item-error': code?.location?.length > 100}"
                                (click)="onEditClick(code, 'location')">{{code?.location ?? ''}}</div>
        
                            <!-- Questions bonus -->                                
                            <div class="codehunt-content-item codehunt-content-item_quiz" (click)="onEditClick(code)">
                                {{getQuizLength(code) > 0 ? (getQuizLength(code) + (getQuizLength(code) > 1 ? '
                                questions' : ' question')) : ''}}</div>
                            
                            <!-- Badge actif/désactivé -->
                            <div class="codehunt-content-item codehunt-content-item_status">
                                <app-status-badge (onStatusChanged)="onStatusChanged($event, code)"
                                    [status]="code?.status"></app-status-badge>
                            </div>
                        </div>
                    </div>


                    
                    <!-- Langue différente -->
                    <div class="codehunt-content-table" *ngIf="!isBaseLanguage">
                        <div class="codehunt-content-row" *ngFor="let code of codesList; let i = index">
                            <!-- Bouton edit -->
                            <div class="codehunt-content-item codehunt-content-item_edit">
                                <app-edit-button (editButtonClicked)="onEditClick(code)"></app-edit-button>
                            </div>

                            <!-- Indice -->
                            <div class="codehunt-content-item codehunt-content-item_hint"
                                [ngClass]="{'codehunt-content-item-to-translate': !code?.hint, 'codehunt-content-item-error': code?.hint?.length > 300}"
                                (click)="onEditClick(code, 'hint')">{{(code?.hint && code?.hint?.length > 0 ? code?.hint : null) ?? (getBaseCode(code)?.hint?.content ? 'À traduire' : null)}}</div>
        
                            <!-- Emplacement -->
                            <div class="codehunt-content-item codehunt-content-item_location"
                                [ngClass]="{'codehunt-content-item-to-translate': !code?.location, 'codehunt-content-item-error': code?.location?.length > 100}"
                                (click)="onEditClick(code)">{{code?.location ?? ''}}</div>
        
                            <!-- Questions bonus -->                                
                            <div class="codehunt-content-item codehunt-content-item_quiz" [ngClass]="{'codehunt-content-item-to-translate': getQuizDiff(code)?.toTranslate === true}" (click)="onEditClick(code)">
                                {{getQuizDiff(code)?.content}}</div>
                            
                            <!-- Badge actif/désactivé -->
                            <div class="codehunt-content-item codehunt-content-item_status"
                                [pTooltip]="!isBaseLanguage ? 'À modifier depuis la langue principale' : null"
                            >
                                <app-status-badge [disabled]="true"
                                    [status]="code?.status"></app-status-badge>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>


</div>