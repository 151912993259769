/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-modal-simple-date-picker',
  templateUrl: './modal-simple-date-picker.component.html',
  styleUrls: ['./modal-simple-date-picker.component.scss']
})
export class ModalSimpleDatePickerComponent implements OnInit, AfterViewInit {
  @ViewChild('calendarRangeCustom') calendarRangeCustom: ElementRef;

  public period: {startDate: string; endDate: string} = null;
  public experienceRange: {startDate: string; endDate: string} = null;
  public type: 'start' | 'end' = null;
  public modalType: 'period' | 'simple' | 'simple-hour' = null;
  public title: string = null;
  public returnType: string = null;
  public maxInterval: number = null;
  public calendarFormGroup: FormGroup;
  showAmountSelected: boolean = false;
  minDate: Date = null;
  maxDate: Date = null;

  public dateStart: Date = null;
  public dateEnd: Date = null;

  get dateStartFmt() {
    if (this.calendarFormGroup?.get('dateStart')?.value && !isNaN(new Date(this.calendarFormGroup?.get('dateStart')?.value as any) as any)) {
      return DateTime.fromISO(new Date(this.calendarFormGroup?.get('dateStart')?.value as any).toISOString()).setLocale('fr').toFormat('dd MMM yyyy');
    }
    return null;
  }
  get dateEndFmt() {
    if (this.calendarFormGroup?.get('dateEnd')?.value && !isNaN(new Date(this.calendarFormGroup?.get('dateEnd')?.value as any) as any)) {
      return DateTime.fromISO(new Date(this.calendarFormGroup?.get('dateEnd')?.value as any).toISOString()).setLocale('fr').toFormat('dd MMM yyyy');
    }
    return null;
  }
  daysAmountSelected: number = 0;

  selectedDate: Date = null;

  isSubmitted: boolean = false;
  errorMessage: string = null;

  constructor(
    private navParams: NavParams,
    private fb: FormBuilder,
    private modalController: ModalController,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.navParams.data?.modalType === 'period') {
      this.modalType = 'period';

      console.log('this.navParams', this.navParams);
      if (this.navParams?.data?.title) {
        this.title = this.navParams.data.title;
      }
      if (this.navParams?.data?.returnType) {
        this.returnType = this.navParams.data.returnType;
      }
      if (this.navParams?.data?.showAmountSelected) {
        this.showAmountSelected = this.navParams.data.showAmountSelected;
      }
      if (this.navParams?.data?.maxInterval) {
        this.maxInterval = this.navParams.data.maxInterval;
      }
      if (this.navParams?.data?.experienceRange) {
        this.experienceRange = this.navParams.data.experienceRange;
        if (this.experienceRange?.startDate) this.minDate = new Date(this.experienceRange?.startDate);
        if (this.experienceRange?.endDate) this.maxDate = new Date(this.experienceRange?.endDate);
      }
      if (this.navParams?.data?.period) {
        this.period = this.navParams.data.period;
        if (this.period?.startDate && this.period?.startDate !== 'jj mm aaaa') {
          let start = DateTime.fromJSDate(this.period?.startDate as any);

          if (!start || !start.isValid) {
            start = DateTime.fromFormat(this.period?.startDate, 'dd MMM yyyy', { locale: 'fr' });
          }
          if (!start || !start.isValid) {
            start = DateTime.fromFormat(this.period?.startDate, 'dd MMM yyyy à HH:mm', { locale: 'fr' });
          };

          this.dateStart = start.toJSDate();
        }
        if (this.period?.endDate && this.period?.endDate !== 'jj mm aaaa') {
          let end = DateTime.fromJSDate(this.period?.endDate as any);

          if (!end || !end.isValid) {
            end = DateTime.fromFormat(this.period?.endDate, 'dd MMM yyyy', { locale: 'fr' });
          }
          if (!end || !end.isValid) {
            end = DateTime.fromFormat(this.period?.endDate, 'dd MMM yyyy à HH:mm', { locale: 'fr' });
          };

          this.dateEnd = end.toJSDate();
        }

        if (this.dateStart && this.dateEnd) this.daysAmountSelected = this.getDaysBetweenDates(this.dateStart, this.dateEnd);
      } else {

      }
      this.initFormGroup();
      if (this.navParams?.data?.type) {
        this.type = this.navParams.data.type;
      }
    } else if (this.navParams.data?.modalType === 'simple' || this.navParams.data?.modalType === 'simple-hour') {
      this.modalType = this.navParams.data.modalType;
      if (this.navParams?.data?.title) {
        this.title = this.navParams.data.title;
      }
      if (this.navParams?.data?.experienceRange) {
        this.experienceRange = this.navParams.data.experienceRange;

        this.minDate = new Date(this.experienceRange?.startDate);
        this.maxDate = new Date(this.experienceRange?.endDate);

        // check if minDate is before date.now and if its the case, set minDate to date.now
        if (this.minDate < new Date()) this.minDate = new Date();
      }

      if (this.navParams?.data?.selectedDate) {
        const date: DateTime = this.navParams.data.selectedDate;
        if (!date || !date.isValid) return;
        const dateJS = date.toJSDate();
        if (!dateJS || isNaN(dateJS as any)) return;

        this.selectedDate = dateJS;
      }
    }
  }

  ngAfterViewInit() {
    // Get the days container
    const elementById = this.el.nativeElement.querySelector('#calendarRangeCustom');
    this.addHoverListeners(elementById);
  }

  addHoverListeners(calendarElement: HTMLElement) {
    // Wait for the calendar days to be rendered
    // setTimeout(() => {
    //   const days = calendarElement.querySelectorAll('.p-datepicker-calendar td span');
    //   days.forEach((day: HTMLElement) => {
    //     this.renderer.listen(day, 'mouseover', () => this.onDayHover(day));
    //     this.renderer.listen(day, 'mouseout', () => this.onDayOut(day));
    //   });
    // });
  }

  onDayHover(day: HTMLElement) {
    // Handle hover in
    console.log('IN:', day.innerText);
  }

  onDayOut(day: HTMLElement) {
    // Handle hover out
    console.log('OUT:', day.innerText);
  }

  onMonthChange(event: any) {
    // console.log('onMonthChange', event);
  }

  onYearChange(event: any) {
    // console.log('onYearChange', event);
  }

  public initFormGroup() {
    let startDate: any = null;
    let endDate: any = null;

    if (this.period?.startDate !== 'jj mm aaaa') {
      if (typeof this.period?.startDate === 'string') {
        startDate = DateTime.fromFormat(this.period?.startDate, 'dd MMM yyyy à HH:mm');
        if (!startDate || !startDate.isValid) startDate = DateTime.fromFormat(this.period?.startDate, 'dd MMM yyyy à HH:mm', { locale: 'fr' });
        if (!startDate || !startDate.isValid) startDate = DateTime.fromFormat(this.period?.startDate, 'dd MMM yyyy', { locale: 'fr' });
        startDate = startDate.toJSDate();
      } else {
        startDate = this.period?.startDate;
      }
    }
    if (this.period?.endDate !== 'jj mm aaaa') {
      if (typeof this.period?.endDate === 'string') {
        endDate = DateTime.fromFormat(this.period?.endDate, 'dd MMM yyyy à HH:mm');
        if (!endDate || !endDate.isValid) endDate = DateTime.fromFormat(this.period?.endDate, 'dd MMM yyyy à HH:mm', { locale: 'fr' });
        if (!endDate || !endDate.isValid) endDate = DateTime.fromFormat(this.period?.endDate, 'dd MMM yyyy', { locale: 'fr' });
        endDate = endDate.toJSDate();
      } else {
        endDate = this.period?.endDate;
      }
    }
    // this.calendarFormGroup = this.fb.group({
    //   dateStart: startDate,
    //   dateEnd: endDate
    // });

    let range = null;
    if (startDate || endDate) range = [startDate, endDate];


    if (!startDate && !endDate) {
      const today: DateTime = DateTime.now();
      // set today to 12:00
      const finalStart = today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const finalEnd = today.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

      startDate = finalStart.toJSDate();
      endDate = finalEnd.toJSDate();
    }

    this.calendarFormGroup = this.fb.group({
      rangeDate: [range, Validators.required],
      hourStart: [startDate, Validators.required],
      hourEnd: [endDate, Validators.required],
    });

    this.calendarFormGroup.valueChanges.subscribe((data: any) => {
      if (data?.rangeDate?.filter(date => date !== null && date !== undefined).length === 2) {
        this.daysAmountSelected = this.getDaysBetweenDates(new Date(data.rangeDate[0]), new Date(data.rangeDate[1]));

        if (this.errorMessage === 'Veuillez sélectionner une période') this.errorMessage = null;
      } else {
        this.daysAmountSelected = 0;
      }

      if (data?.rangeDate && data?.rangeDate.length === 2) {
        this.dateEnd = new Date(data.rangeDate[0]);
      }
      if (data?.rangeDate && data?.rangeDate.length >= 1) {
        this.dateStart = new Date(data.rangeDate[1]);
      }
    });
  }

  public getDaysBetweenDates(startDate: Date, endDate: Date) {
    const start = DateTime.fromJSDate(startDate);
    const end = DateTime.fromJSDate(endDate);

    start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    end.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    if (!start || !start.isValid || !end || !end.isValid) return 0;

    const diff = end.diff(start, 'days').toObject();
    const days = diff.days;

    return Math.ceil(days) + 1;
  }


  async closeModal(data: any) {
    await this.modalController.dismiss(data);
  }

  onConfirmClick() {
    this.isSubmitted = true;
    this.errorMessage = null;

    if (this.modalType === 'simple' || this.modalType === 'simple-hour') {
      if (this.selectedDate === null) {
        this.errorMessage = 'Veuillez sélectionner une date';
        return;
      }
      this.closeModal(this.selectedDate);
    } else if (this.modalType === 'period') {
      const range = this.calendarFormGroup.get('rangeDate')?.value;

      if (range === null || range === undefined || range.length !== 2 || range[0] === null || range[0] === undefined || range[1] === null || range[1] === undefined) {
        this.errorMessage = 'Veuillez sélectionner une période';
        return;
      }

      if (this.calendarFormGroup.get('hourStart')?.value === null || this.calendarFormGroup.get('hourStart')?.value === '') {
        this.errorMessage = 'Veuillez sélectionner une heure de début';
        return;
      }

      if (this.calendarFormGroup.get('hourEnd')?.value === null || this.calendarFormGroup.get('hourEnd')?.value === '') {
        this.errorMessage = 'Veuillez sélectionner une heure de fin';
        return;
      }

      if (this.daysAmountSelected < 1) {
        this.errorMessage = 'La période sélectionnée est invalide';
        return;
      }

      if (this.maxInterval && this.daysAmountSelected > this.maxInterval) {
        this.errorMessage = 'La période sélectionnée est trop longue<br>(' + this.maxInterval + ' jours maximum)';
        return;
      }

      // compare start and end date to check if start date is on the same day as end date
      const start = DateTime.fromISO(new Date(range[0]).toISOString()).setLocale('fr').toFormat('dd MMM yyyy');
      const end = DateTime.fromISO(new Date(range[1]).toISOString()).setLocale('fr').toFormat('dd MMM yyyy');

      if (start === end) {
        // now check if start hour is before end hour
        const startHour = DateTime.fromISO(new Date(this.calendarFormGroup.get('hourStart')?.value).toISOString()).setLocale('fr').toFormat('HH:mm');
        const endHour = DateTime.fromISO(new Date(this.calendarFormGroup.get('hourEnd')?.value).toISOString()).setLocale('fr').toFormat('HH:mm');

        if (startHour >= endHour) {
          this.errorMessage = 'Veuillez choisir une heure de fin supérieure à l\'heure de début';
          return;
        }
      }

      if (!this.returnType) {
        this.period = {
          startDate: DateTime.fromISO(new Date(range[0]).toISOString()).setLocale('fr').toFormat('dd MMM yyyy') + ' à ' + DateTime.fromISO(new Date(this.calendarFormGroup.get('hourStart')?.value).toISOString()).setLocale('fr').toFormat('HH:mm'),
          endDate: DateTime.fromISO(new Date(range[1]).toISOString()).setLocale('fr').toFormat('dd MMM yyyy') + ' à ' + DateTime.fromISO(new Date(this.calendarFormGroup.get('hourEnd')?.value).toISOString()).setLocale('fr').toFormat('HH:mm')
        };
      } else if (this.returnType === 'Datetime') {
        const startFinal = DateTime.fromISO(new Date(range[0]).toISOString()).setLocale('fr').toFormat('yyyy-MM-dd') + 'T' + DateTime.fromISO(new Date(this.calendarFormGroup.get('hourStart')?.value).toISOString()).setLocale('fr').toFormat('HH:mm:ss');
        const endFinal = DateTime.fromISO(new Date(range[1]).toISOString()).setLocale('fr').toFormat('yyyy-MM-dd') + 'T' + DateTime.fromISO(new Date(this.calendarFormGroup.get('hourEnd')?.value).toISOString()).setLocale('fr').toFormat('HH:mm:ss');
        this.period = {
          startDate: startFinal,
          endDate: endFinal
        };
      }


      // if (this.calendarFormGroup.get('dateStart')?.value !== null && this.calendarFormGroup.get('dateStart')?.value !== '') {
      //   this.period.startDate = DateTime.fromISO(new Date(this.calendarFormGroup.get('dateStart')?.value).toISOString()).setLocale('fr').toFormat('dd MMM yyyy');
      // } else {
      //   this.period.startDate = 'jj mm aaaa';
      // }
      // if (this.calendarFormGroup.get('dateEnd')?.value !== null && this.calendarFormGroup.get('dateEnd')?.value !== '') {
      //   this.period.endDate = DateTime.fromISO(new Date(this.calendarFormGroup.get('dateEnd')?.value).toISOString()).setLocale('fr').toFormat('dd MMM yyyy');
      // } else {
      //   this.period.endDate = 'jj mm aaaa';
      // }
      this.closeModal(this.period);
    }
  }
}
